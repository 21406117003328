
// import { base_entity } from './base_entity';
// import { nursing_home } from './nursing_home';

// export class user extends base_entity {
//     user_role_id: number;
//     name: string;
//     user_name: string;
//     password: string;
//     phone_no: string;
//     login_id: string;
//     email: string;
//     pin_no: string;
//     designation: string;
//     nursing_home_id: number;
//     nursing_home: nursing_home;
//     pharmacy_id: number;
//     api_token: string;
//     group_id: number;
// }

// import { base_entity } from './base_entity';
// import { vehicle } from './vehicle';

export class user {
  user_role_id: number;
  appraiser_id: number;



  api_token: string;
  company_id: number;
  created_by_id: number;
  created_on: string;
  email: string;
  email_verified_at: string;
  employee_id: number;
  id: number;
  password: string;
  remember_token: string;
  status: number;
  updated_by_id: number;
  user_name: string;
  mobile: any;
  first_name: string;
  last_name: string;
}
