import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs';
import { CommonHelper } from './CommonHelper';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(
    private helper: CommonHelper,
    private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.helper.GetCurentUser().api_token;
    var user_id = this.helper.GetCurentUser()?.id;
    var company_id = this.helper.GetCurentUser()?.company_id;
    var auth_key = this.helper.GetLocalStorage('customer_auth', false)
    if (token == undefined) token = "";
    if (company_id == undefined) company_id = 0;
    if (user_id == undefined) user_id = 0;
    request = request.clone({
      setHeaders: {
        'Authorization': `${token}`,
        'user_id': this.helper.EncryptWithURL(String(user_id), token),
        'company_id': this.helper.EncryptWithURL(String(company_id), token),
        'customerauthorization': String(auth_key)
      }
    });
    return next.handle(request).pipe(
      tap(
        async event => {
          if (event instanceof HttpResponse) {
            let body = event.body;
            if (body?.Type == "UA") {
              let spinner = await this.helper.ShowSpinner();
              this.helper.ErrorToster("Token expired please login again to continue");
              this.helper.DeleteAllLocalStorage();
              this.router.navigate(['login']);
              this.helper.HideSpinner(spinner);
            }
          }
        },
        error => {

        }
      )
    );
  }
}
