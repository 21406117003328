import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { user } from 'src/Model/user';
import { ToastController, NavController, LoadingController, Platform } from '@ionic/angular';
import { FormGroup, FormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
declare let cordova: any;
@Injectable({ providedIn: 'root' })
export class CommonHelper {
  constructor(
    private toastController: ToastController,
    private nav: NavController,
    private loadingController: LoadingController,
    private platform: Platform,
  ) {
    this.ApiURL = environment.API_URL;
    this.StorageName = "lightPeConsumerApp";
  }
  ApiURL: string;
  StorageName: string;
  SetLocalStorage(name: string, data: any) {
    window.localStorage.setItem(name, JSON.stringify(data));
  }
  GetLocalStorage(name: string, jsonformat: boolean = false) {

    if (jsonformat)
      return JSON.parse(window.localStorage.getItem(name));
    else
      return window.localStorage.getItem(name);

  }
  DeleteAllLocalStorage() {
    return window.localStorage.clear();
  }

  DeleteLocalStorage(name: string) {
    return window.localStorage.removeItem(name);
  }
  GetCurentUser() {
    let User: user;
    User = new user();
    let data = JSON.parse(window.localStorage.getItem(this.StorageName));
    if (data != null) {
      User = data;
    }
    return User;
  }
  PageNavigation(uri: string) {
    this.nav.navigateForward(uri);
  }
  async SuccessToster(Message: string) {
    const toast = await this.toastController.create({
      message: Message,
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }
  async ErrorToster(Message: string) {
    const toast = await this.toastController.create({
      message: Message,
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }
  async ShowSpinner() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();
    return loading;
  }
  HideSpinner(loading: HTMLIonLoadingElement) {
    loading.dismiss();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  convertBaseb64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  Encrypt(text: string) {
    if (text == null) return text;
    var OriginalKey = CryptoJS.AES.encrypt(String(text), environment.API_URL).toString();
    var DuplicateKey = CryptoJS.enc.Base64.parse(OriginalKey);
    return DuplicateKey.toString(CryptoJS.enc.Hex);
  }

  Decrypt(text: string) {
    if (text == null)
      return text;
    var DuplicateKey = CryptoJS.enc.Hex.parse(text);
    var OriginalKey = DuplicateKey.toString(CryptoJS.enc.Base64);
    return CryptoJS.AES.decrypt(OriginalKey, environment.API_URL).toString(CryptoJS.enc.Utf8);
  }
  EncryptWithURL(text: string, url: string) {
    if (text == null)
      return text;
    var OriginalKey = CryptoJS.AES.encrypt(String(text), url).toString();
    var DuplicateKey = CryptoJS.enc.Base64.parse(OriginalKey);
    return DuplicateKey.toString(CryptoJS.enc.Hex);
  }

}
